import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, #root, #__next {
    width: 100%;
    scroll-behavior: smooth;
  }
  
  /* reCaptcha banner css */
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

export default GlobalStyle;
