import React, { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import locales from '../../../locales';
import env from '../../env';
import { getThemeByClientId } from '../../styles/Theme';
import { ToastContainer } from '../Toast';
import { useLocale } from './localeProvider';
import GlobalStyles from '../../styles/GlobalStyles';

const AppProvider: React.FC = ({ children }) => {
  const locale = useLocale();
  const messages = useMemo(() => locales[locale], [locale]);

  // Gets the current client id from the env
  const clientId = useMemo(() => env().NEXT_PUBLIC_CLIENT_ID, []);

  // Gets the theme for this current clientId
  const theme = useMemo(() => getThemeByClientId(clientId), [clientId]);

  return (
    <div id="app-provider">
      <IntlProvider locale={locale} messages={messages}>
        <ThemeProvider theme={theme}>
          {children}
          <ToastContainer />
          <GlobalStyles />
        </ThemeProvider>
      </IntlProvider>
    </div>
  );
};

export default AppProvider;

// unused variable needed to use generic types in .tsx files
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const withAppProvider = <T, _>(Component: React.FC<T>) => {
  const ComponentWithProvider: React.FC<T> = (props) => (
    <AppProvider>
      <Component {...props} />
    </AppProvider>
  );

  return ComponentWithProvider;
};
